<template>
    <section>
        <p class="product__info">{{ $t('realForceInfo.desc') }}</p>
        <UiLoader v-if="!dataReady" theme="block" :loading="true" />

        <div 
            class="tabs-offset matrix-grid" 
            v-if="dataReady"
        >
            <!-- <MatrixCard 
                v-for="card in classicMatrix" 
                :key="card.lvl" 
                :card="card" 
                :account="account" 
            /> -->

            <SingleMatrixCard 
                v-for="card in classicMatrix" 
                :key="card.lvl" 
                :card="card" 
                :account="account" 
                :achiveLevel="cardAchiveLevel"
            />
        </div>
    </section>
</template>
<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import pageReadyByConnect from '@/mixin/pageReadyByConnect'
// import MatrixCard from '@/components/Matrix/Card.vue'
import SingleMatrixCard from '@/components/Matrix/SingleMatrixCard.vue'

export default {
  data() {
    return {
      id: null,
      account: null,
    }
  },

  components: {
    // MatrixCard,
    SingleMatrixCard,
  },

  mixins: [pageReadyByConnect],

  async mounted() {
    if (this.isConnected) {
      await this.pageReady()
    }
  },

  computed: {
    ...mapState('user', ['classicMatrix']),
    ...mapGetters('crypto', ['getAccount']),
    
    dataReady() {
      return this.account && this.classicMatrix && this.classicMatrix.length
    },

    cardAchiveLevel() {
        return this.classicMatrix.filter(el => el.active).length + 2
    },
  },

  methods: {
    ...mapActions('crypto', ['getProgramLevels', 'checkRegistration']),
    ...mapActions('user', ['guessUser']),
    ...mapMutations('user', ['setClassicMatrix', 'updateClassicMatrixLevels']),

    async pageReady() {
      const [regErr, regParent] = await this.checkRegistration(true)
      if (regErr) {
        this.$swal(this.$t('errors.registrationError'))
        await this.$router.push({ name: 'signup' })
        return
      }

      const accountQuery = this.$route.query.account
      const targetAccount = accountQuery || this.getAccount

      try {
        let userResponse = await this.guessUser(targetAccount)
        if (!userResponse) throw new Error()

        this.id = userResponse.name
        this.account = userResponse.account

        this.setClassicMatrix(userResponse.classic)

        // this.$toast.info(userResponse.classic)

        const [err, levels] = await this.getProgramLevels(userResponse.account)

        if (err) {
          this.$toast.error(`Fetch program level from blockchain error: ${err}`)
        }

        this.updateClassicMatrixLevels(levels)
      } catch (err) {
        this.$swal(this.$t('errors.userNotFound'))
        await this.$router.push({ name: 'dashboard' })
      }
    },

  },
}
</script>