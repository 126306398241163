<template>
  <div class="cards-outer">
    <UiLoader v-if="!arePropsReady" theme="block" :loading="true" />

    <div class="single-card" v-else>
      <div class="system__levels-item" :class="{ active: !card.active }">
        <router-link :to="{ name: 'classiclevel', params: { lvl: card.lvl + 1 } }" class="system__levels-item-link"
          :class="{ 'card_link-not-active': !card.active }">
          <div class="system__levels-item_title">
            <span>{{$t('matrix.level')}} {{ card.lvl + 1 }}</span>
            {{ card.cost }} DAI
            <p>{{ (blockchain && blockchain.slot) || 0 }} {{$t('matrix.stats.cycles')}}</p>
          </div>
          <RenderTypeCard :cardType="card.type" :active="card.active" :blockchain="blockchain" />

          <div class="button-plus__outer">
            <button type="button" class="button-plus" v-show="(!card.active && achiveLevel <= card.lvl + 1) || false">
              <svg style="margin-right: 2px" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M8 9.875H8.00571M4 6.75H12V13H4V6.75ZM5.71429 3H10.2857V6.75H5.71429V3Z" stroke="white"
                  stroke-width="1.75" stroke-linecap="square" stroke-linejoin="bevel"></path>
              </svg>
              {{ $t("matrix.achievePrev") }}
            </button>
          </div>
        </router-link>
        <div class="system__levels-item_overlay" v-show="!card.active">
          <div class="overlay__sum">{{ card.cost }} DAI</div>
          <ButtonLight :text="$t('matrix.activation')" classes="button-light-local" @event="handleBuyClick"
            v-show="(!card.active && achiveLevel > card.lvl + 1) || false" />
        </div>
        <div class="system__levels-partners">
          <BaseIcon name="cardPartners" verAlign />
          <span class="system__levels-partners-count">{{ blockchain ? blockchain.totalPartners : 0 }}</span>
        </div>
        <div class="system__levels-item_progressbar" v-show="card.active">
          <div class="progress__line" :style="`width: ${progressPercent}`"></div>
          <div class="progress__number" :style="`left: ${progressPercent}`">
            {{ progressPercent }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { isSameAccount } from '@/helpers/user'
import { Log } from '@/helpers/dev'
import routerNavigation from '@/mixin/routerNavigation'
import RenderTypeCard from './Render/RenderTypeCard.vue'
import ButtonLight from '@/components/UI/ButtonLight.vue'
import BaseIcon from '@/components/UI/BaseIcon.vue'
export default {
  mixins: [routerNavigation],

  props: {
    card: Object,
    account: String,
    achiveLevel: Number,
  },

  components: {
    RenderTypeCard,
    ButtonLight,
    BaseIcon,
  },

  data() {
    return {
      error: false,
      loading: false,
      blockchain: null,
      arePropsReady: null,
    }
  },
  async mounted() {
    localStorage.removeItem("progressBar");
    setTimeout(() => {
      const propsCount = Object.keys(this.card).length
      if (propsCount > 0) {
        return (this.arePropsReady = true)
      }
    }, 1500)

    // request sub-methods
    if (!this.card.active) return

    await this.getStructure()
  },
  computed: {
    cardActive() {
      // for watcher
      if (this.card) return this.card.active
    },

    progressPercent() {
      if (this.blockchain) {
        const { slot, totalPartners } = this.blockchain
        if (totalPartners) {
          const totalInSlot = this.card.type === 's6' ? 6 : 3
          const activeInSlot =
            Number(slot) !== 0
              ? totalPartners - totalInSlot * Number(slot)
              : totalPartners

          const calc = Math.round((activeInSlot / totalInSlot) * 100)
          return `${calc}%`
        }
      }

      return '0%'
    },
    isMyAccount() {
      return isSameAccount(this.getAccount, this.account)
    },
    autoUpgradeButtonsAvailable() {
      if (this.card.lvl === 11) return false
      return this.previousBuyLevel === this.card.lvl
    },
    buyAvailable() {
      if (!this.isMyAccount) return false

      if (this.card.active === 'gap') return true
      return this.currentBuyLevel === this.card.lvl
    },
    ...mapGetters('user', [
      'currentBuyLevel',
      'previousBuyLevel',
      'gappedLevel',
    ]),
    ...mapGetters('crypto', ['getAccount', 'getMainContract']),
  },
  methods: {
    async getStructure() {
      this.loading = true
      this.error = false

      const [err, res] = await this.requestStructure({
        account: this.account,
        level: this.card.lvl,
        type: this.card.type,
        countRevenue: false,
        previousActiveSlot: this.card.active === 'gap',
      })

      if (err) {
        this.$toast.error(
          `Fetch structure for level: ${this.card.lvl} from blockchain error: ${err}`
        )
      }

      if (err) this.error = true

      this.blockchain = res
      Log(`${this.$t('matrix.lvl')} - ${this.card.lvl}`, res)

      this.loading = false
    },
    handleBuyClick() {
      if (this.gappedLevel !== null && this.gappedLevel < this.card.lvl) {
        this.$swal(this.$t('matrix.activateGap', { lvl: this.card.lvl + 1 }))
        return
      }
      this.setActivationModal({
        visible: true,
        type: this.card.type,
        level: this.card.lvl,
        cost: this.card.cost,
      })
    },
    ...mapActions('crypto', ['requestStructure']),
    ...mapMutations('ui', ['setActivationModal']),
  },
  watch: {
    cardActive(newVal, oldVal) {
      if (newVal !== false && !oldVal) {
        this.getStructure()
      }
    },
  },
}
</script>


<style lang="scss" scoped>
.button-light-local {
  background: var(--color-orange-900);
  padding: var(--indent-14) var(--indent-62);
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  letter-spacing: 0.5px;
  font-weight: var(--font-600);
  color: var(--color-white);
  max-width: 180px;
  z-index: 1000;
  transition: background 500ms;
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--color-orange-800);
  }
}

.card_link-not-active {
  pointer-events: none;
  cursor: initial;
}
</style>